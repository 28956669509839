<header id="header">
    <mat-toolbar>
        <div class="left-block" [ngClass]="{
            'background': !sideNavClose
        }">
            <button mat-icon-button (click)="onSideNavToggle()">
                <mat-icon svgIcon="menu_icon"></mat-icon>
            </button>

            <span class="restaurant-picture">
                <img class="profile-pic" src="/assets/images/tastio-logo.svg" />
                <mat-chip-list class="title status">
                    <mat-chip class="small opacity" *ngIf="!mobileMode">
                        <span class="opacity-7">Portal</span>
                    </mat-chip>
                </mat-chip-list>
            </span>
        </div>

        <a [href]="customerPanelUrl" rel="noopener noreferrer" target="_blank" class="preview-btn il ml-3"
            *ngIf="!mobileMode" color="primary" mat-stroked-button>
            <mat-icon class="flex" svgIcon="eye_icon"></mat-icon>
            Preview Store
        </a>
        <span class="spacer"></span>
        <button mat-button id="olvy-target" *ngIf="!mobileMode">
            <mat-icon svgIcon="flag_icon"></mat-icon>
            What's New?
        </button>

        <app-theme-switch #tooltip="matTooltip" matTooltip="{{theme.endsWith('dark-theme')?'Light': 'Dark'}} Mode"
            [matTooltipPosition]="'below'" class="ml-3"></app-theme-switch>

        <button color="primary" *ngIf="!mobileMode" mat-icon-button matTooltip="Select Theme"
            [matMenuTriggerFor]="themeMenu" class="ml-3">
            <mat-icon svgIcon="paint_bucket_icon"></mat-icon>
        </button>

        <button mat-icon-button matTooltip="Notifications" [matMenuTriggerFor]="notification" color="primary"
            class="ml-3">
            <mat-icon [matBadge]="unreadNotificationCount" matBadgePosition="after" matBadgeOverlap="true"
                matBadgeSize="small" matBadgeColor="warn" svgIcon="bell_icon"></mat-icon>
        </button>
        <button mat-icon-button [matMenuTriggerFor]="menu" class="ml-3">
            <figure class="profile-pic">
                <img [src]="profile?.profileImageLink ? (S3_URL+''+BUCKET_NAME+'/'+profile.profileImageLink) : '/assets/images/avatar.svg'"
                    alt="">
            </figure>
            <!-- <img [src]="profileImageLink? (S3_URL+''+BUCKET_NAME+'/'+profileImageLink) : '/assets/images/avatar.svg'" alt=""> -->
            <!-- <img class="profile-pic" src="/assets/images/avatar.svg" /> -->
            <!-- <img class="profile-pic" [src]="S3_URL + '' + basicInfo.bucketName + '/' + basicInfo.imageLink" /> -->
        </button>

    </mat-toolbar>

    <!-- profile menu -->
    <mat-menu #menu="matMenu" xPosition="before" class="menu">
        <button mat-menu-item routerLink="profile">
            <mat-icon>person</mat-icon>
            <span>Profile</span>
        </button>
        <button mat-menu-item (click)="logout()">
            <mat-icon>power_settings_new</mat-icon>
            <span>Log Out</span>
        </button>
    </mat-menu>

    <!-- notification menu -->
    <mat-menu #notification="matMenu" xPosition="before" class="menu custom-scrollbar notification-menu">
        <app-notification-menu [markAllRead]="markAllRead" (unreadCount)="getUnreadCount($event)">
        </app-notification-menu>
    </mat-menu>

    <!-- theme menu -->
    <mat-menu #themeMenu="matMenu" xPosition="before" class="menu">
        <button mat-menu-item role="menuitemradio" aria-checked="true" *ngFor="let theme of themeList"
            (click)="selectTheme(theme)">
            <mat-icon color="accent">{{selectedTheme?.name === theme.name ? 'radio_button_checked' :
                'radio_button_unchecked'}}</mat-icon><span>{{theme.name}}</span>
        </button>
    </mat-menu>
</header>