import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DesignUtilityServices } from './design-utility.service';
import { LogoutResolver } from './logout/logout.resolver';

@Injectable({
  providedIn: 'root'
})
export class ServerErrorInterceptorService implements HttpInterceptor {

  constructor(
    private _logoutResolver: LogoutResolver,
    private _du:DesignUtilityServices
    ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = 'Something went wrong.';

        if (error?.error === 'Unauthorized' || error.status === 401) {
          errorMessage = 'Invalid Credentials';
          this._logoutResolver.logout();
          return throwError(errorMessage);
        }

        if (error?.status === 500 && error.error.details[0] === 'No Logged In User Found') {
          this._logoutResolver.logout();
          errorMessage = error.error?.details[0];
          this._du.openCustomSnackBar('Session timeout, Please login again', 'Close', 'error');
          return throwError(errorMessage);
        }

        if (error?.status === 500) {
          errorMessage = error.error?.details[0];
          return throwError(errorMessage);
        }

        return throwError(errorMessage);
      })
    );
  }
}
