import {Directive, ElementRef, Input} from '@angular/core';
 
@Directive({
    selector: '[bannerImage]'
})
export class BannerImageDirective {
    private el: HTMLElement;
    imgPath=[
      '/assets/images/banner-bg/bg1.jpg',
      '/assets/images/banner-bg/bg2.jpg',
      '/assets/images/banner-bg/bg3.jpg',
      '/assets/images/banner-bg/bg4.jpg',
      '/assets/images/banner-bg/bg5.jpg',
      '/assets/images/banner-bg/bg6.jpg'
    ];

    get randomImg(){
      return this.imgPath[Math.floor(Math.random()*this.imgPath.length)];
    }

    constructor(el: ElementRef) {
      this.el = el.nativeElement;
    }

    ngAfterViewInit() {
      this.el.style.backgroundImage = 'url(' + this.randomImg + ')';
    }
 
}