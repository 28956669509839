import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Profile } from '../interfaces/IProfile';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  api:string =  environment.api;

  profile = new BehaviorSubject<Profile>(null); 
  domain = new BehaviorSubject<string>(null);

  constructor(
    private httpClient: HttpClient,
    ) { }
  // getAddresses(): Observable<any>{
  //  return this.httpClient.get(this.api+"/addresses");
  // }

  /**
   * Get Domain by username
   * @param username 
   * @returns 
   */
  getDomain(username: string | number) {
    this.httpClient.get(`${this.api}/tenant/domain/name/${username}`).subscribe((res:string)=>{
      this.domain.next(res.toLowerCase());
    });
  }

  

  getProfile(){
    this.httpClient.get(this.api+"/user/profile").subscribe((res:any) =>{
      if(res){
        const filteredProfile = {
          firstName: res.firstName,
          lastName: res.lastName,
          emailId: res.emailId || res.mobileNumber,
          role: res.role,
        };

        localStorage.setItem('loggedInUser', JSON.stringify(filteredProfile));

        this.profile.next(res);
        this.getDomain(res.emailId || res.mobileNumber);
      }
    })
  }

  updateFirstName(firstName){
    return this.httpClient.patch(`${this.api}/user/firstName/${firstName}`, firstName, {withCredentials: true});
  }

  updateLastName(lastName){
    return this.httpClient.patch(`${this.api}/user/lastName/${lastName}`, lastName, {withCredentials: true});
  }

  updateLogo(imageUrlValue){
    return this.httpClient.patch(`${this.api}/user/image/${imageUrlValue}`, imageUrlValue, {withCredentials: true});
  }
}
