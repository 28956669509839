import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NotificationStorage {
    notificationList = new BehaviorSubject(null);

    notifyRestroNotificationList = new BehaviorSubject(null);
    public setNotificationList(notificationList: any[]) {
        this.notificationList.next(notificationList);
        this.getNotificationListByNotificationType();
    }

    public getNotificationListByNotificationType() {
        const listByType = this.notificationList.value.filter(notification => {
            if (notification.notificationModel.notificationObject.type === "notify-restro") {
                return notification;
            }
        });
        this.notifyRestroNotificationList.next(listByType);
    }
}