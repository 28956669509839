<div class="dialog-wrapper">
    <h3>Change Password</h3>
    <a mat-icon-button (click)="onNoClick()" class="close">
        <mat-icon class="material-icons-outlined">close</mat-icon>
    </a>
    <div class="dialog-content">
        <form [formGroup]="Form" class="flex-column">
            <mat-form-field appearance="outline">
                <mat-label>New Password</mat-label>
                <input matInput formControlName="password" placeholder="Enter Updated Password" required
                    [type]="hide2 ? 'password' : 'text'">
                <mat-icon matSuffix (click)="hide2 = !hide2">
                    {{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Enter OTP</mat-label>
                <input matInput formControlName="otp" placeholder="Enter OTP" required type="number">
            </mat-form-field>
        </form>
    </div>
    <div class="dialog-footer">
        <div class="btn-row">
            <button mat-button class="a-btn" type="button"
                (click)="generateOTP()" color="primary">
                Resend otp
            </button>
            <button mat-flat-button class="btn" color="primary" (click)="onChangePassword()" 
                [disabled]="!Form.valid || loading">
                <span [class.vHidden]="loading">Change password</span>
                <mat-spinner color="primary" *ngIf="loading" diameter="20"></mat-spinner>
            </button>
        </div>
    </div>
    <!-- <p style="text-align: center;" class="success" *ngIf="generateOTPSuccess">OTP is successfully sent to your mail id</p> -->
</div>

 


