<footer>
    <mat-divider></mat-divider>
    <div class="footer-container">
        <div class="right-container">
            <mat-icon color="primary">copyright</mat-icon>
            <span>{{currentYear}} powered by 
                <a href="https://www.tastio.com" class="foreground" target="#" >
                    <strong>
                        Tastio
                    </strong>
                </a>
            </span>
             
        </div>
    </div>

</footer>