<div class="prompt-container">
    <h1 mat-dialog-title>{{data.title}}</h1>
    <div class="msg bg-card-4">
        <img class="img" *ngIf="data?.img" src="{{data.img}}" alt="">
        <p *ngIf="text" [innerHTML]="text"></p>
    </div>
    <div mat-dialog-actions align="start">
        <button mat-button class="light" [mat-dialog-close]="'editing'">Edit</button>
        <button mat-flat-button color="warn" [mat-dialog-close]="'sent'">Send</button>
    </div>
</div>
