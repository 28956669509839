import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProfileService } from '../appServices/profile.service';

export interface RefreshTokenResponse {
  token: string;
}

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private myTokenCache: Observable<RefreshTokenResponse>;
  private myToken: string;
  private myUserId: number;
  private myTokenSubscription: Subscription;
  private stopTimer: Subject<boolean>;

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private _profileService: ProfileService
  ) { }



  public createTokenHeader(): HttpHeaders {
    let reqOptions = new HttpHeaders().set('Content-Type', 'application/json')
    this.token = this.cookieService.get('token');
    if (this.token) {
      reqOptions = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', `Bearer ${this.token}`);
    }
    return reqOptions;
  }

  public clear() {
    if (this.myTokenSubscription) {
      this.myTokenSubscription.unsubscribe();
    }
    if (this.stopTimer) {
      this.stopTimer.next(true);
      this.stopTimer = null;
    }
    this.myTokenCache = null;
    this.myToken = null;
    this.myUserId = null;
    this.router.navigate(['/auth']);
  }

  get tokenStream(): Observable<string> {
    return this.myTokenCache.pipe(map(response => response.token));
  }

  get token(): string {
    return this.myToken;
  }

  set token(token: string) {
    this.myToken = token;
  }

  get userId(): number {
    return this.myUserId;
  }

  set userId(userId: number) {
    this.myUserId = userId;
  }



  public setTokensAndUserName(userName, token) {
    this.setTokenToTokenService(userName, token);
    this.setTokenToCookies(userName, token);
    this.getProfile();
  }

  public setTenantName(tenantName: string) {
    this.cookieService.delete('tenant-name');
    this.cookieService.set('tenant-name', tenantName, 30, "/");
  }
  public getTenantName() {
    return this.cookieService.get('tenant-name');
  }
  /**
   * set token to cookies
   * @param userName 
   * @param token 
   */
  private setTokenToCookies(userName, token) {
    this.cookieService.set('username', userName, 30, "/");
    this.cookieService.set('token', token, 30, "/");
  }

  /**
   * set token to token Service
   * @param userName 
   * @param token 
   */
  private setTokenToTokenService(userName, token) {
    this.token = token;
    this.userId = userName;
  }


  public getProfile() {
    this._profileService.getProfile();

  }
  /**
   * clear token
   */
  public clearToken() {
    //this.accountSettings.next(null);
    this.cookieService.delete('username', '/');
    this.cookieService.delete('token', '/');
    this.cookieService.delete('tenant-name', '/');
    this.cookieService.deleteAll();
    sessionStorage.clear();
    this._profileService.profile.next(null);
  }
}
