import { Directive, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appFontScale]'
})
export class FontScaleDirective implements OnInit {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.scaleFont();
  }

  @HostListener('window:resize')
  onResize() {
    this.scaleFont();
  }

  private scaleFont() {
    const containerWidth = this.el.nativeElement.offsetWidth;
    const currentFontSize = parseFloat(window.getComputedStyle(this.el.nativeElement).fontSize);

    const fontSize = Math.min((containerWidth / 10) * (currentFontSize / 16), currentFontSize); // Adjust the division as needed

    this.renderer.setStyle(this.el.nativeElement, 'font-size', `${fontSize}px`);
  }
}
