import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MdePopoverTrigger } from '@material-extended/mde';
import { BreakpointServices } from 'src/app/appServices/breakpoint.service';
import { DesignUtilityServices } from 'src/app/appServices/design-utility.service';
import { HelperService } from 'src/app/appServices/helper.service';
import { ProfileService } from 'src/app/appServices/profile.service';
import { LogoutResolver } from 'src/app/appServices/logout/logout.resolver';
import { ThemeService } from 'src/app/appServices/theme.service';
import { AccountSettingsStorage } from 'src/app/appStorage/account-settings.storage';
import { Profile } from 'src/app/interfaces/IProfile';
import { environment } from 'src/environments/environment';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends HandleSubscriptionsComponent implements OnInit {
  @ViewChild(MdePopoverTrigger, { static: false }) trigger: MdePopoverTrigger;

  closePopover() {
    this.trigger.togglePopover();
  }

  //------------------------------------------// 
  // Properties Area                          //
  //------------------------------------------//

  theme; // Global Theme
  sideNavClose;

  themeList: any[];
  selectedTheme: any;
  mobileMode: boolean = false;

  basicInfo: any;
  profile:Profile;
  BUCKET_NAME: string;
  S3_URL:string = environment.S3_URL;

  baseUrl = environment.baseUrl;
  domain:string;
  get customerPanelUrl(){
    return this.baseUrl+'/'+this.domain
  }
  
  constructor(
    public dialog: MatDialog,
    private _du: DesignUtilityServices,
    private _themeService: ThemeService,
    private _breakPoint: BreakpointServices,
    private _logoutResolver: LogoutResolver,
    private _profileService: ProfileService,
    private helperService: HelperService,
    private accountSettingsStorage: AccountSettingsStorage) {
    super();
    this.subscribeTheme();
    this.subscribeSideNavClose();
    this.subscribemobileMode();
  }

  subscribeTheme() {
    this.handleSubscription(this._themeService.theme,
      (res) => {
        this.theme = res
      }
    );
  }

  subscribeSideNavClose() {
    this.handleSubscription(this._du.sideNavClose,
      (res) => {
        this.sideNavClose = res
      }
    );
  }

  subscribemobileMode() {
    this.handleSubscription(this._breakPoint.mobileMode,
      (res) => {
        this.mobileMode = res
      }
    );
  }

  ngOnInit(): void {
    this.getBucketName();
    this.themeList = this._themeService.themeList;
    this.subscribeSelectedTheme();
    this.subscribeBasicInfo();
    this.onGetProfile();
    this.getDomain();
  }



  

  getDomain(){
    this.handleSubscription(this._profileService.domain,
      (res)=>{
      this.domain = res;
    })
  }

  onGetProfile(){
    this.handleSubscription(this._profileService.profile,
      (res)=>{
      this.profile = res;
    });
  }

  /**
   * toggleTheme
   */
  // toggleTheme() {
  //   this.theme = !this.theme;
  //   this._themeService.theme.next(this.theme);
  // }

  onSideNavToggle() {
    this.sideNavClose = !this.sideNavClose;
    this._du.sideNavClose.next(this.sideNavClose);
  }


  selectTheme(theme) {
    this._themeService.selectedTheme.next(theme);
  }
  // Subscribtion

  subscribeSelectedTheme() {
    this.handleSubscription(this._themeService.selectedTheme,
      (res) => {
      this.selectedTheme = res;
    });
  }

  logout() {
    this._logoutResolver.logout();
  }

  //################# Notification ############

  markAllRead: boolean;
  unreadNotificationCount: number;
  notificationClosed() {
    this.markAllRead = !this.markAllRead;
  }

  /**
   * Unread notification count
   */
  getUnreadCount(value) {
    this.unreadNotificationCount = value;
  }

  subscribeBasicInfo() {
    this.handleSubscription(this.accountSettingsStorage.accountSettings,
      (res) => {
      this.basicInfo = res;
    });
  }

/**
* get bucket name from storage
*/
  getBucketName() {
    this.handleSubscription(this.helperService.bucketName,
      (res) => {
      this.BUCKET_NAME = res;
    })
  }
}
