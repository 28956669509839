import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  public themeList = [
    {
      value: 'blue-amber',
      name: 'Blue & Amber'
    },
    {
      value: 'indigo-pink',
      name: 'Indigo & Pink'
    },
    {
      value: 'pink-blue-gray',
      name: 'Pink & Blue Gray'
    },
    {
      value: 'purple-green',
      name: 'Purple & Green'
    },
    {
      value: 'green-blue',
      name: 'Green & Blue'
    }
  ];
  
  theme = new BehaviorSubject('dark-theme');  // For Header Color
  selectedTheme = new BehaviorSubject(this.themeList[4]);
  
  
}
