import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { TokenService } from '../token.service';

@Injectable({
  providedIn: 'root'
})
export class TenentContextService {

  constructor(
    private tokenService: TokenService
  ) { }

  getTenantForHostname(hostname: string): string {
      const tenant = this.tokenService.getTenantName();
  //   const tenant = 'taqueriachavez';
    
    if (tenant !== null) {
      return this.getTenantForHost(tenant);
    }
  }

  getTenantForString(s: string) {
    return s;
  }

  getTenantForHost(host: string): string {
    return this.getTenantForString(host);
  }

  getTenant(): string {
    return this.getTenantForHostname(location.hostname);
  }

  addTenantToHeaders(headers: HttpHeaders): HttpHeaders {
    const tenantName = this.getTenant();
    if (tenantName) {
      return headers.append("tenant-name", this.getTenant());
    }
    return headers;
  }
}
