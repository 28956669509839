import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  StripeCardElementOptions,
  StripeElementsOptions
} from '@stripe/stripe-js';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { DesignUtilityServices } from 'src/app/appServices/design-utility.service';
import { MarketingService } from 'src/app/appServices/marketing.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-stripe-card-payment',
  templateUrl: './stripe-card-payment.component.html',
  styleUrls: ['./stripe-card-payment.component.scss']
})
export class StripeCardPaymentComponent implements OnInit {

  basicInfo: any;
  disablePayButton = false;
  fetching: boolean = false;


  stripeTest: FormGroup;

  elementsOptions: StripeElementsOptions = {
    //locale: 'es'
  };


  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  cardOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#7A7A7A',
        lineHeight: '40px',
        fontWeight: '600',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };


  constructor(
    public dialogRef: MatDialogRef<StripeCardPaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private stripeService: StripeService,
    private router: Router,
    private _du: DesignUtilityServices,
    private marketingService: MarketingService
  ) {
  }

  ngOnInit(): void {
    this.stripeService.setKey(environment.STRIPE_PUBLIC_KEY);
    this.stripeTest = this.fb.group({
      name: ['', [Validators.required]]
    });
  }


  onNoClick(): void {
    this.dialogRef.close(this.data);
  }


  buy(): void {
    this.fetching = true;
    this.disablePayButton = true;
    const name = this.stripeTest.get('name').value;
    this.stripeService
      .createToken(this.card.element, { name })
      .subscribe(result => {
        if (result.token) {
          console.log(result.token.id);
          this.chargeCard(result.token.id);
        } else if (result.error) {
          this.disablePayButton = false;
          // Error creating the token
          console.log(result.error.message);
          this._du.openCustomSnackBar(result.error.message, 'Close', 'error');
        }
      }, err => {
        this.fetching = false;
        this.disablePayButton = false;
        this._du.openCustomSnackBar('Failed to Create Order', 'Close', 'error');
      });
  }

  chargeCard(token: string): void {
    this.fetching = true;
    this.data.paymentModel.paymentToken = token;

    if (this.data.type === 'marketing') {

      this.marketingService.broadCastSms(this.data).subscribe(res => {
        this.fetching = false;
        this.data.result = 'success';
        this.postPayment();
      }, err => {
        this.data.result = 'error';
        this.fetching = false;
        this.disablePayButton = false;
        this._du.openCustomSnackBar(err, 'Close', 'error');
      });


      // this.orderService.createOrder(this.data, 'stripe').subscribe(res => {
      //   this.fetching = false;
      //   this.data.id = res;
      //   this.postPayment();
      // }, err => {
      //   this.fetching = false;
      //   this.disablePayButton = false;
      //   this._du.openCustomSnackBar('Error in creating order, If any money detected from your account will be refund.', 'Close', 'error');
      // });
    }

  }

  postPayment(): void {
    this.disablePayButton = false;
    this.onNoClick();
  }



  onCloseModel(): void {
    this.dialogRef.close('CLOSE');
  }

}
