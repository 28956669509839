import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  canActivate(): boolean {
    if (this.cookieService.get("token")) {
      return true;
    }

    this.router.navigate(['auth']);
    return false;

  }
  constructor(private router: Router, private cookieService: CookieService) {
  }
}



@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(private router: Router, private cookieService: CookieService) {
  }

  canActivate(): boolean {
    const token = this.cookieService.get("token");
    if (token) {
      this.router.navigate(['/get-started']);
      return false;
    }
    return true;
  }  
}