<input [type]="type" [placeholder]="placeHolder" 
class="editable-field"
[ngClass]="{
    'saved': saved,
    'cus-number-input':type=='number' 
}"
[(ngModel)]="modelObject.modelValue" 
(focus)="modelObject.oldValue = modelObject.modelValue" 
(blur)= "updateAttributeValue(modelObject)"
(keydown)="onTyping()"
(keyup.enter)="updateAttributeValue(modelObject)"
/>