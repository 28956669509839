import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  bucketName = new BehaviorSubject(null);

  constructor(

  ) { 
    
  }





}
