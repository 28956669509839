import { Injectable, Injector } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { RollbarService } from 'src/app/app.module';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ErrorLoggingService {

  constructor(private injector: Injector, private cookieService: CookieService) {
    const loggedInUsername = this.cookieService.get('username');
    const tenantName = this.cookieService.get('tenant-name');
    const rollbar = this.injector.get(RollbarService);
    rollbar.configure({
      environment: environment.production ? 'prod' : 'dev',
      payload: {
        username: loggedInUsername ? loggedInUsername : 'NA',
        tenantName: tenantName ? tenantName : 'NA'
      }
    });
  }

  logError(message: string, stack: string) {
    // Send errors to server here
    const rollbar = this.injector.get(RollbarService);
    console.log('LoggingService: ' + message);
    rollbar.error(new Error(message).stack);
  }

  logInfo(message: string) {
    const rollbar = this.injector.get(RollbarService);
    const loggedInUsername = this.cookieService.get('username');
    rollbar.info(message, { username: loggedInUsername ? loggedInUsername : 'NA' });
  }

  logWarning(message: string) {
    const rollbar = this.injector.get(RollbarService);
    const loggedInUsername = this.cookieService.get('username');
    const tenantName = this.cookieService.get('tenant-name');
    rollbar.warning(message, { username: loggedInUsername ? loggedInUsername : 'NA', tenantName: tenantName ? tenantName : 'NA' });
  }
}
