<div class="image-upload-container">
  <button class="close" mat-icon-button (click)="onNoClick()">
    <mat-icon>close</mat-icon>
  </button>
  <h3>Upload Image</h3>
  <input
    type="file"
    #inpFile
    (change)="fileChangeEvent($event)"
    required
    class="dnone"
    accept="image/png, image/jpeg, image/jpg"
  />

  <image-cropper
    *ngIf="imageChangedEvent"
    [imageChangedEvent]="imageChangedEvent"
    [aspectRatio]="aspectRatio"
    [maintainAspectRatio]="true"
    [containWithinAspectRatio]="true"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"
    (cropperReady)="cropperReady()"
    (loadImageFailed)="loadImageFailed()"
    [resizeToWidth]="resizedWidth"
    format="png"
  >
  </image-cropper>
  <div
    *ngIf="!imageChangedEvent"
    class="bg-card outline-box outline background-5 dragOver"
    [ngClass]="{
      'dragOver': dragOver
    }"
    (drop)="onDropFile($event)"
    (dragover)="onDragOverFile($event)"
    (dragleave)="dragOver=false"
    (click)="inpFile.click()"
  >
    <span class="text" *ngIf="!imageChangedEvent"
      >
      <mat-icon color="primary" [class.animate]="dragOver">cloud_upload </mat-icon> 
      <span class="title" [class.animate]="dragOver">
        {{dragOver?'Drop here': 'Drag & Drop files'}}
      </span>
      <ng-container *ngIf="!dragOver">
        <span class="or">
          or
        </span>
        <span class="sub-title primary">
          Browse your file
        </span>
      </ng-container>
    </span>
  </div>

  <div class="row">
    <div class="col-6">
      <a
        mat-flat-button
        color="primary"
        [mat-dialog-close]="data.croppedImage"
        cdkFocusInitial
      >
        Save
      </a>
      <a
        *ngIf="imageChangedEvent"
        class="ml-2"
        mat-button
        (click)="inpFile.click()"
      >
        Change
      </a>
    </div>
  </div>
</div>
