import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IMessage } from 'src/app/pages/marketing/campaigns/interfaces/IMessage';
import { environment } from 'src/environments/environment';
import { TokenService } from '../auth/token.service';

@Injectable({
  providedIn: 'root',
})
export class MarketingService {

  api: string = environment.api;

  constructor(
    private httpClient: HttpClient,
    private tokenService: TokenService
  ) { }

  /**
   * get message by type
   * @param pageIndex
   * @param type
   */
  getMessages(pageIndex, type): Observable<any> {
    return this.httpClient.get<IMessage[]>(this.api + `/marketing/page/20/${pageIndex}?type=${type}`);
  }


  /**
   * get message by type
   */
  getMessageByType(type): Observable<any> {
    return this.httpClient.get(this.api + '/marketing/' + type);
  }

  /**
   * broadcast message
   * @param data
   */
  broadCastMessage(data): Observable<any> {
    return this.httpClient.post(this.api + '/message/broadcast', data);
  }

  /**
   * broadcast sms
   * @param data
   */
  broadCastSms(data): Observable<any> {
    return this.httpClient.post(this.api + '/marketing/sms', data);
  }
  /**
   * broadcast mms
   * @param data
   */
  broadCastMms(data): Observable<any> {
    return this.httpClient.post(this.api + '/marketing/mms', data);
  }

  /**
   * broadcast mms
   * @param data
   */
  broadCastPush(data): Observable<any> {
    return this.httpClient.post(this.api + '/marketing/pushnotification', data);
  }

  /**
   * broadcast email
   * @param data
   * @returns
   */
  broadCastEmail(data): Observable<any> {
    return this.httpClient.post(this.api + '/marketing/email', data);
  }

  /* get unsubscribe types by mobile
   * @param mobileNumber
   * @returns
   */
  getUnsubscribeTypesByMobile(mobileNumber: string): Observable<any> {
    return this.httpClient.get(
      `${this.api}/marketing/unsubscribeTypes/${mobileNumber}`
    );
  }

  subscirbeByMobileNumber(type: string, mobile: string): Observable<any> {
    return this.httpClient.put(
      `${this.api}/marketing/subscribe/${type}/${mobile}`,
      null
    );
  }

  unSubscirbeByMobileNumber(type: string, mobile: string): Observable<any> {
    return this.httpClient.put(
      `${this.api}/marketing/unsubscribe/${type}/${mobile}`,
      null
    );
  }

  subscriberCountByType(type): Observable<any> {
    return this.httpClient.get(
      `${this.api}/marketing/subscriber/count/${type}`
    );
  }

  /**
   * get Service Fee
   * @returns
   */
  getServiceFee(): Observable<any> {
    return this.httpClient.get(
      `${this.api}/payment-gateway/client/stripe/fee`
    )
  }

  /**
   * get per message cost by type
   * @param messageType
   * @returns
   */
   getPerMessageCostByType(messageType: string): Observable<any> {
    //  if (this.tokenService.getTenantName() === 'taqueriachavez' || this.tokenService.getTenantName() === '6j0vbr') {
    //      return of(0); // Free for taqueriachavez and 6j0vbr
    //  } else {
         if (messageType === 'sms') {
             return of(0.03); // Cost for SMS for other tenants
         }
         if (messageType === 'mms') {
             return of(0.06); // Cost for MMS for other tenants
         }
    //  }
 }


 /**
   * broadcast email
   * @param data
   * @returns
   */
 sendEmail(data): Observable<any> {
  return this.httpClient.post(this.api + '/marketing/send-email', data);
 }


}
