<h3 mat-dialog-title>Discard Changes?</h3>
<mat-dialog-content>
  <p>
	  You have unsaved changes. 
    Are you sure you want to leave this page? Unsaved changes will be lost.
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Stay on this page</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>
    Discard and leave page.
  </button>
</mat-dialog-actions>
