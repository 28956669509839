<!-- PROGRESS BAR -->
<tastio-progress-bar></tastio-progress-bar>
<!-- / PROGRESS BAR -->

<ngx-loading-bar [color]="'#ffcc00'" [height]="'5px'" [includeSpinner]="false">
  <div class="loader-div"></div>
</ngx-loading-bar>

<div class="mat-drawer-container mat-app-background">
  <!-- <app-theme-switch></app-theme-switch> -->
  <router-outlet></router-outlet>
</div>