import { Injectable } from '@angular/core';
import { Category } from '../interfaces/ICategory';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
 
  
  api:string = environment.api+"/category";

  constructor(private httpClient: HttpClient) {
   }

  getCategoryByParentId(id: number): Observable<any> {
    return this.httpClient.get(this.api + "?parentCategoryId=" + id);
  }


  getAllParentCategories(menuId: number): Observable<any> {
    return this.httpClient.get(this.api + "/getAll/" + menuId);
  } 


  addCategory(category: Category): Observable<any> {
    return this.httpClient.post(this.api +"/create", category, {withCredentials: true});
  }


  removeCategory(categoryId: number): Observable<any> {
    return this.httpClient.delete(this.api + "/" + categoryId, {withCredentials: true});
  }

  deleteCategoiesByMenu(menuId: number): Observable<any> {
    return this.httpClient.delete(this.api + "/menu/" + menuId, {withCredentials: true});
  }


  getCategories(): Observable<any> {
    return this.httpClient.get(this.api);
  }


  getCategoryById(id):Observable<any>{
    return this.httpClient.get(this.api + "/" + id);
  }


  getChildCategoriesById(id):Observable<any>{
    return this.httpClient.get(this.api + "/getChilds/" + id);
  }


  updateCategory(category:Category):Observable<any>{
    return this.httpClient.put(this.api + "/update/" + category.id, category, {withCredentials: true});
  }
  

  updateCategorySequence(category:Category):Observable<any>{
    return this.httpClient.put(this.api + "/update/sequence/" + category.id, category, {withCredentials: true});
  }




}
