import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AccountSettingsService } from '../appServices/account-settings.service';
import { HelperService } from '../appServices/helper.service';
import { ProfileService } from '../appServices/profile.service';
import { MenuResolver } from '../pages/menu-setup/service/menu.resolver';
@Injectable({
    providedIn: 'root'
})
export class AccountSettingsStorage {
    accountSettings = new BehaviorSubject(null);
    constructor(
        private accountSettingsService: AccountSettingsService,
        private helperService: HelperService,
        private profileService: ProfileService,
        private menuResolver: MenuResolver
    ) {
    }

    initiateApp() {
        this.getAccountSettings();
        this.profileService.getProfile();
        this.menuResolver.getMenu();
    }

    /**
     * get Account settings 
     */
    getAccountSettings() {
        if (!sessionStorage.getItem("basic-info")) {
            this.setRestaurantBasicInfoFromApi();
        } else {
            this.accountSettings.next(JSON.parse(sessionStorage.getItem("basic-info")));
            this.helperService.bucketName.next(JSON.parse(sessionStorage.getItem("basic-info")).bucketName);
        }
    }

    setRestaurantBasicInfoFromApi() {
        this.accountSettingsService.getAccountInfo().subscribe(res => {
            sessionStorage.setItem("basic-info", JSON.stringify(res));
            this.helperService.bucketName.next(res.bucketName);
            this.accountSettings.next(res);
        })
    }

    public setBasicInfo(basicInfo: any) {
        sessionStorage.setItem("basic-info", JSON.stringify(basicInfo));
        this.accountSettings.next(basicInfo);
    }

    cleanAccountSettings() {
        this.accountSettings.next(null);
        sessionStorage.clear();
    }

    getAccountInfo(){
        return this.accountSettingsService.getAccountInfo();
    }
    
    updateBusinessType(businessType: string){
        return this.accountSettingsService.updateBusinessType(businessType);
    }

}