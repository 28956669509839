import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Variant } from '../interfaces/IVariant';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VariantService {
 
  api:string = environment.api + "/variant";
  constructor(private httpClient: HttpClient) {
  }


  getAllVariants(menuId: number): Observable<any> {
    return this.httpClient.get(`${this.api}/getAllVariants/${menuId}`);
  }
  addVariant(variant: Variant): Observable<any> {
    return this.httpClient.post(this.api+"/create", variant, {withCredentials: true});
  }
  removeVariant(variantId: number): Observable<any> {
    return this.httpClient.delete(this.api + "/" + variantId, {withCredentials: true});
  }

  deleteVariantsByMenu(menuId: number): Observable<any> {
    return this.httpClient.delete(this.api + "/menu/" + menuId, {withCredentials: true});
  }

  updateVariant(variant: Variant): Observable<any> {
    return this.httpClient.put(this.api + "/update/" + variant.id, variant, {withCredentials: true});
  }

  updateVariantName(variantId: number, variantName: string): Observable<any> {
    return this.httpClient.patch(`${this.api}/${variantId}/${variantName}`, {withCredentials: true})
  }

  toggleVariant(variant: Variant): Observable<any> {
    return this.httpClient.patch(`${this.api}/status/${variant.id}/${variant.active}`,null);
  }

  getChildVariantById(id): Observable<any> {
    return this.httpClient.get(this.api + "/getChilds/" + id);
  }

  getVariantsCountByMenuId(menuId: number): Observable<any> {
    return this.httpClient.get(this.api + "/count/" + menuId);
  }

  getAllVariantsByPages(menuId: number, pageSize: number, pageIndex: number, search: string, status: boolean): Observable<any> {
    return this.httpClient.get(`${this.api}/${menuId}/${pageSize}/${pageIndex}?search=${search}&status=${status}`);
  }

  getAllVariantGroups(menuId: number): Observable<any> {
    return this.httpClient.get(`${this.api}-group/getAll/${menuId}`);
  }
  
  addVariantGroup(variantGroup: any): Observable<any> {
    return this.httpClient.post(`${this.api}-group`, variantGroup, {withCredentials: true});
  }
  
  deleteVariantsGroup(groupId: number): Observable<any> {
    return this.httpClient.delete(`${this.api}-group/${groupId}`, {withCredentials: true});
  }
  
  mapWithInventory(variantId:number, inventoryIds:number[]): Observable<any>{
    return this.httpClient.post(`${this.api}/map-with-inventory/${variantId}`, inventoryIds, {withCredentials: true});
  }
  
  mapVariantsWithGroup(variantIds:number[], groupId:number): Observable<any>{
    return this.httpClient.post(`${this.api}-group/map-variants/${groupId}`, variantIds, {withCredentials: true});
  }
  
  removeVariantFromGroup(variantGroupId: number ,variantId: number): Observable<any> {
    return this.httpClient.delete(`${this.api}-group/map-variants/${variantGroupId}/${variantId}`, {withCredentials: true});
  }

  toggleVariantGroupActiveStatus(variantGroupId: number, status:boolean): Observable<any>{
    return this.httpClient.patch(`${this.api}-group/status/${variantGroupId}/${status}`, {withCredentials: true})
  }

  updateVariantGroupName(variantGroupId: number, name:string): Observable<any>{
    return this.httpClient.patch(`${this.api}-group/${variantGroupId}/${name}`, {withCredentials: true})
  }

  toggleVariantGroupMultiselect(variantGroupId: number, status:boolean): Observable<any>{
    return this.httpClient.patch(`${this.api}-group/multi-select/${variantGroupId}/${status}`, {withCredentials: true})
  }

}
