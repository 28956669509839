<ejs-richtexteditor #toolsRTE id='alltoolRTE' (keydown)="onTyping()" 
    (blur)="onBlurEditor()"
    [(value)]='value' [toolbarSettings]='tools'>
</ejs-richtexteditor>

<div class="btn-row" *ngIf="typing">
    <button mat-mini-fab class="submit" (click)="onDescriptionSubmit()">
        <mat-icon>done</mat-icon>
    </button>
    <button mat-mini-fab class="discard" (click)="onDescriptionDiscard()">
        <mat-icon>clear</mat-icon>
    </button>
</div>