import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ToolbarService,
  LinkService,
  ImageService,
  HtmlEditorService,
  TableService,
} from '@syncfusion/ej2-angular-richtexteditor';

@Component({
  selector: 'app-richtext',
  templateUrl: './richtext.component.html',
  providers: [
    ToolbarService,
    LinkService,
    ImageService,
    HtmlEditorService,
    TableService,
  ],
  styleUrls: ['./richtext.component.scss'],
})
export class RichtextComponent implements OnChanges, AfterViewInit {
  constructor() {}
  @Input() public value;
  @Output() onModelChanged = new EventEmitter();
  @Output() onBlur = new EventEmitter();
  tempValue;

  @ViewChild('toolsRTE') public toolsRTE;

  ngOnChanges(changes: SimpleChanges): void {
    this.tempValue = this.value;
  }

  ngAfterViewInit(): void {
    this.toolsRTE.refreshUI();
  }
  
  public tools: object = {
    items: [
      'Bold',
      'Italic',
      'CreateLink',
      'UnorderedList',
      'OrderedList',
      '|',
      'Undo',
      'Redo'
    ],
  };

  onDescriptionSubmit() {
    this.onModelChanged.emit(this.value);
    this.typing = false;
  }

  onDescriptionDiscard() {
    this.value = this.tempValue;
    this.typing = false;
  }
  typing;
  onTyping() {
    this.typing = true;
  }

  onBlurEditor() {
    this.onBlur.emit(this.value);
  }
}
