import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
@Component({
  selector: 'app-add-input',
  templateUrl: './add-input.component.html',
  styleUrls: ['./add-input.component.scss']
})
export class AddInputComponent implements AfterViewInit{
  @Input() public placeholder;
  @Output() onModelChanged = new EventEmitter();
  @ViewChild('inp') inp: ElementRef;
  value='';

  constructor() { }

  ngAfterViewInit(){
    this.inp.nativeElement.focus();
  }
  onSubmit(){
    this.onModelChanged.emit({
      type:'submit',
      value: this.value
    });
    this.typing = false;
  }

  onDiscard(){
    this.onModelChanged.emit({
      type:'discard',
      value: ''
    })
    this.typing = false;
  }
  
  typing;
  onTyping(){
    this.typing = true;
  }

}
