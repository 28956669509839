<div class="input-wrapper">
    <input type="text" 
    #inp
    class="addInput"
    (keydown)="onTyping()"
    
    (keyup.enter)="onSubmit()"
    [placeholder]="placeholder" 
    [(ngModel)]='value'>
    
    <div class="btn-row" *ngIf="typing">
        <button mat-mini-fab class="submit" (click)="onSubmit()">
            <mat-icon>done</mat-icon>
        </button>
        <button mat-mini-fab class="discard" (click)="onDiscard($event)">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
</div>