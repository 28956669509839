import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { NotificationStorage } from 'src/app/appStorage/notification.storage';
import { NotificationService } from 'src/app/appServices/notification.service';
import { Notification } from 'src/app/interfaces/INotification';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';

@Component({
  selector: 'app-notification-menu',
  templateUrl: './notification-menu.component.html',
  styleUrls: ['./notification-menu.component.scss']
})

export class NotificationMenuComponent extends HandleSubscriptionsComponent implements OnInit, OnChanges, OnDestroy   {

  @Input()
  markAllRead: boolean;

  @Output()
  unreadCount = new EventEmitter();

  notificationInterval: any;
  notificationList: Notification[];
  notifyRestroSound: boolean;

  constructor(
    private notificationService: NotificationService,
    private notificationStorage: NotificationStorage,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getNotificationInInterval();
  }
  ngOnChanges(simpleChange: SimpleChanges) {
    if (this.notificationList) {
      this.readAll();
    }
  }
  ngOnDestroy() {
    clearInterval(this.notificationInterval);
  }

  getNotificationInInterval() {
    this.notificationInterval = setInterval(() => {
      this.getNotifications((this.notificationList && this.notificationList.length > 0) ? this.notificationList[0].id : 0);
    }, 20000);
  }

  /**
   * clear all notifications
   */
  clearAll() {
    if (this.notificationList && this.notificationList.length > 0) {
      const ids = this.notificationList.map(e => {
        return e.id
      });
      this.handleSubscription(this.notificationService.clearAll(ids),
        (res) => {
        },
        (err) => {
        }
      );

      this.notificationList = [];
    }

    this.calculateUnreadNotificationCount();

  }

  /**
   * mark all read
   */
  readAll() {
    let ids = [];
    this.notificationList.forEach(e => {
      if (!e.read) {
        e.read = true;
        ids.push(e.id);
      }
    });
    if (ids.length > 0) {
      this.handleSubscription(this.notificationService.markAllRead(ids),
      (res) => {
        this.calculateUnreadNotificationCount();
      });
    }
  }

  /**
   * get all notifications
   * @param id 
   */
  getNotifications(id: number) {
    this.handleSubscription(this.notificationService.getNotificationsAfterId(id),
    (res) => {
      if (res) {
        this.notificationList = this.notificationList ? res.concat(this.notificationList) : res;
        this.calculateUnreadNotificationCount();
        this.notificationStorage.setNotificationList(this.notificationList);
      }
    });
  }


  /**
 * calculate and emit unread count
 */
  calculateUnreadNotificationCount() {
    const count = this.notificationList.filter(e => !e.read).length;
    this.unreadCount.emit(count);
  }


}
