import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeResolver {

  constructor() { }

  getTimeSpanToDays(days: number): Date {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    let calculatePreviousDate = new Date(today);
    calculatePreviousDate.setDate(calculatePreviousDate.getDate() + days);
    return calculatePreviousDate;
  }

  getCurrentDate(): Date {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  }

  getTimeSpanFromDays(days: number): Date {
    const today = new Date();
    let calculatePreviousDate = new Date(today);
    calculatePreviousDate.setDate(calculatePreviousDate.getDate() - days);
    return calculatePreviousDate;
  }

  getYesterdayDate(): Date {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    let calculatePreviousDate = new Date(today);
    calculatePreviousDate.setDate(calculatePreviousDate.getDate() - 1);
    return calculatePreviousDate;
  }

  getPreviousSelectedDate(selectedDate): Date {
    const today = new Date(selectedDate);
    today.setHours(0, 0, 0, 0);
    const calculatePreviousDate = new Date(today);
    calculatePreviousDate.setDate(calculatePreviousDate.getDate() - 1);
    return calculatePreviousDate;
  }

  formatRelativeTime(timestamp: number): string {
    const now = Date.now();
    const diffInMs = now - timestamp;

    const seconds = Math.floor(diffInMs / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if
      (years > 0) {
      return `${years} year${years > 1 ? 's' : ''} ago`;
    } else if (months > 0) {
      return `${months} month${months > 1 ? 's' : ''} ago`;
    } else
      if (weeks > 0) {
        return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
      } else if (days > 0) {
        return `${days} day${days > 1 ? 's' : ''} ago`;
      } else if (hours > 0) {
        return `${hours} hour${hours > 1 ? 's' : ''} ago`;
      } else if (minutes > 0) {
        return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
      } else {
        return 'Just now';
      }
  }

}