import { Component, OnInit, Output, Input, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-editable-field',
  templateUrl: './editable-field.component.html',
  styleUrls: ['./editable-field.component.scss']
})
export class EditableFieldComponent implements OnInit, OnChanges {

  @Input() modelValue: any ;
  @Input() placeHolder: any;
  @Input() type: string = 'text';
  @Output() onModelChanged = new EventEmitter();

  saved:boolean = false;
  modelObject: any = {};

  constructor() { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {
    this.modelObject.modelValue = this.modelValue;
  }

  /**
   * emit if model value is changed.
   * @param modelObject 
   */
  updateAttributeValue(modelObject) {
    const oldValue = typeof modelObject.oldValue === 'string' ? modelObject.oldValue.trim() : '';
    const modelValue = typeof modelObject.modelValue === 'string' ? modelObject.modelValue.trim() : '';
  
    if (oldValue !== modelValue) {
      this.onModelChanged.emit(modelValue);
    }
    this.saved = true;
  }

  onTyping(){
    this.saved = false;
  }
  
}
