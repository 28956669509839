import { Injectable } from '@angular/core';
import { Product } from '../interfaces/IProduct';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BulkModifyModel } from '../interfaces/IBulkModifyModel';

@Injectable({
  providedIn: 'root'
})
export class ProductService {



  api:string = environment.api + "/product";

  constructor(private httpClient: HttpClient) { }

  addProduct(product: Product): Observable<any> {
    return this.httpClient.post(this.api + "/create", product, { withCredentials: true });
  }

  getProductsCount(): Observable<any> {
    return this.httpClient.get(this.api + "/count");
  }
  
  getAllProducts(menuId: number): Observable<any> {
    return this.httpClient.get(this.api + "/getAll/" + menuId, { withCredentials: true });
  }
  // getProductsByCategoryId(id: number): Observable<any> {
  //   return this.httpClient.get(this.api + "?categoryId=" + id);
  // }
  getProductsByCategoryId(id:number): Observable<any>{
    return this.httpClient.get(this.api + '/category/' + id + "?client=customer");
    // return this.httpClient.get(this.api + 'product/category/' + id + "?client=outdoor-kiosk");
  }
  updateProduct(product: Product): Observable<any> {
    return this.httpClient.put(this.api + "/update/" + product.id, product, { withCredentials: true });
  }
  getProductById(id: number): Observable<any> {
    return this.httpClient.get(this.api + "/" + id, { withCredentials: true });
  }
  deleteProduct(id: number): Observable<any> {
    return this.httpClient.delete(this.api + "/" + id, { withCredentials: true });
  }

  updateProductStatuc(id: number, status: boolean): Observable<any> {
    return this.httpClient.patch(this.api + "/status/" + id + "/" + status, { withCredentials: true });
  }

  updateProductPrice(id: number, price: number): Observable<any> {
    return this.httpClient.patch(this.api + "/update/price/" + id + "/" + price, { withCredentials: true });
  }

  mapStore(productId: number, productStoreModelList: any[]): Observable<any> {
    return this.httpClient.patch(this.api + "/store/" + productId, productStoreModelList, { withCredentials: true });
  }

  getProductIdsByMenu(menuId: number): Observable<any> {
    return this.httpClient.get(this.api + "/getAllIds/" + menuId);
  }

  getProductsByTopping(toppingId: number): Observable<any> {
    return this.httpClient.get(this.api + "/variant/" + toppingId);
  }

  mapOrderType(productId: number, ids: number[]): Observable<any> {
    return this.httpClient.patch(this.api + "/mapOrderType/" + productId + "?orderTypeIds=" + ids , { withCredentials: true });
  }

  
  /**
   * update product category
   * @param productId 
   * @param categoryId 
   * @returns 
   */
  updateProductCategory(productId: number, categoryId: number): Observable<any> {
    return this.httpClient.patch(`${this.api}/category/${productId}/${categoryId}`, null);
  }


  /**
   * Get all products by page by category
   * @param menuId 
   * @param pageSize 
   * @param pageIndex 
   * @param search 
   * @param status 
   * @returns 
   */
  getAllProductsByPageWithCategory(menuId:number,pageSize: number, pageIndex: number, categoryId:number = 0, search: string, status:boolean): Observable<any> {
    if(categoryId===0){
      return this.httpClient.get(`${this.api}/getAll/${menuId}/${pageSize}/${pageIndex}?search=${search}&status=${status}`);
    }
    return this.httpClient.get(`${this.api}/getAll/${menuId}/${pageSize}/${pageIndex}?categoryId=${categoryId}&search=${search}&status=${status}`);
  }


  /**
   * bulk modify price
   */
  bulkModifyPrice(bulkModifyModel: BulkModifyModel): Observable<any> {
    return this.httpClient.put(this.api + "/bulk-modify", bulkModifyModel, { withCredentials: true });
  }

}
