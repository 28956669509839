import { Directive, Output, HostListener, HostBinding, ElementRef, EventEmitter, Input } from '@angular/core';

@Directive({
  selector: '[appExpand]'
})
export class ExpandDirective {
  @Input() toggle: boolean;
  constructor(private elementRef: ElementRef) { }
  @HostBinding('class.open') isOpen: boolean = false;
  // @HostListener('click') toggleOpen() {
  //   this.isOpen = !this.isOpen;
  // }

  @Output() clickElsewhere = new EventEmitter<MouseEvent>();
  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;
    const targetChild = targetElement.classList.contains('stopToggle');
    // Check if the click was outside the element
    if (targetElement && !this.elementRef.nativeElement.contains(targetElement)) {

      this.clickElsewhere.emit(event);
      this.isOpen = false;
    }
    else {
      if (targetChild || !this.toggle) {
        this.isOpen = true;
      } else {
        this.isOpen = !this.isOpen;
      }
    }

  }

}