import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  api:string = environment.api;
  
  loggedIn = new BehaviorSubject(false);
  
  constructor(private httpClient: HttpClient) { }



  /*------------------------------------------------------------------
    Common Apis
  -------------------------------------------------------------------*/
  
  /**
   * Get Tenant By Username (Mobile || Email) || 
   * @param username 
   * @returns 
   */
  getTenant(username: any): Observable<any> {
    return this.httpClient.get(`${this.api}/tenant/username/${username.username}?countryName=${username.countryName|| ''}`);
  }

  
  /**
   * Get Tenant By Domain 
   * @param domain 
   * @returns 
   */
  getTenantByDomain(domain: string): Observable<any> {
    return this.httpClient.get(`${this.api}/tenant/domain/${domain}`);
  }


  /*------------------------------------------------------------------
    New Tenant Apis
  -------------------------------------------------------------------*/

  /**
   * For [New Tenant] Request Again OTP For New User:
   * Use getTenant() Method
   */
    



  /**
   * Verify New Tenant OTP
   * @param data 
   * @returns 
   */
  verifyOTP(data:any) {
    return this.httpClient.post(`${this.api}/tenant/verifyOTP`,
      {
        otp: data.otp,
        username: data.username.username
      }
    );
  }




  
  /*------------------------------------------------------------------
    Existing Tenant Apis
  -------------------------------------------------------------------*/

  /**
   * Login By Password
   * @param data 
   * @returns 
   */
   loginByPassword(data:any) {
    return this.httpClient.post(`${this.api}/authenticate`,
      {
        userName: data.username.username,
        password: data.password,
        userRoleModel: {
          userRole :"admin"
        },
        asOTP:false
      }
    );
  }




  /**
   * Generate OTP for existing User 
   * @param username 
   * @returns 
   */
   generateOtp(username:any): Observable<any> {
    return this.httpClient.get(`${this.api}/user/generateOTP/username/${username.username}`);
  }




  /**
   * Login By OTP
   * @param data 
   * @returns 
   */
  loginByOtp(data:any) {
    return this.httpClient.post(`${this.api}/authenticate`,
      {
        userName: data.username.username,
        password: data.password,
        userRoleModel: {
          userRole :"admin"
        },
        asOTP:true
      }
    );
  }


  /**
   * Login By Pin
   * @param data 
   * @returns 
   */
  loginByPin(user:any): Observable<any> {
    return this.httpClient.post(`${this.api}/authenticate`, user, { withCredentials: true });
  }



  /**
   * Reset Password
   * @param data 
   * @returns 
   */
  resetPassword(data:any): Observable<any>{
    return this.httpClient.put(this.api + "/user/password", data, { withCredentials: true });
  }



  


  
  updatePassword(passwordForm): Observable<any> {
    passwordForm.mobileNumber = sessionStorage.getItem('username');
    return this.httpClient.put(this.api + "/user/password", passwordForm, { withCredentials: true });
  }

  generateOTPByEmail(emailId:string): Observable<any> {
    return this.httpClient.get(this.api + `/user/generateOTP/email/${emailId}`, { withCredentials: true });
  }

  changePassword(data:any): Observable<any>{
    return this.httpClient.put(this.api + "/user/password", data, { withCredentials: true });
  }
}

