import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../includes/header/header.component';
import { ThemeSwitchComponent } from '../includes/theme-switch/theme-switch.component';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { HttpClientModule } from '@angular/common/http';
import { FooterComponent } from '../includes/footer/footer.component';
import { NotificationMenuComponent } from '../includes/popups/notification-menu/notification-menu.component';
import { DateAgoPipe } from 'src/app/utilities/pipe/date-ago.pipe';

import { DesignUtilitiesModule } from './design-utilities.module';
import { UpdatePasswordComponent } from 'src/app/includes/update-password/update-password.component';

@NgModule({
    declarations : [
        ThemeSwitchComponent,
        HeaderComponent,
        FooterComponent,
        NotificationMenuComponent,
        DateAgoPipe,
        UpdatePasswordComponent,
        
    ],
    imports : [
        CommonModule,
        HttpClientModule,
        LoadingBarRouterModule,
        LoadingBarHttpClientModule,
        DesignUtilitiesModule
    ],
    exports : [
        LoadingBarRouterModule,
        ThemeSwitchComponent,
        FooterComponent,
        HeaderComponent,
        NotificationMenuComponent,
        DateAgoPipe,
        UpdatePasswordComponent
    ]
})
export class CoreModule{

}
