import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AccountSettingsStorage } from 'src/app/appStorage/account-settings.storage';
import { TokenService } from 'src/app/auth/token.service';
import { StoreResolver } from '../store/store.resolver';
import { MenuResolver } from 'src/app/pages/menu-setup/service/menu.resolver';
import { DesignUtilityServices } from '../design-utility.service';

@Injectable({
  providedIn: 'root'
})
export class LogoutResolver {


  constructor(
    private tokenService: TokenService,
    private _du: DesignUtilityServices,
    private router: Router,
    private accountSettingsStorage: AccountSettingsStorage,
    private _menuResolver: MenuResolver,
    private _storeResolver: StoreResolver,
  ) {

  }



  /**
   * logout
   */
  public logout() {
    if (this.router.url.startsWith("/auth")) {
      return;
    }
    this.tokenService.clearToken();
    this.accountSettingsStorage.accountSettings.next(null);
    this.router.navigate(['/auth']);
    this._menuResolver.clear();
    this._storeResolver.clear();
    localStorage.removeItem('loggedInUser');
    this._du.openCustomSnackBar('Successfully logout!', 'Close', 'success');
  }

}
