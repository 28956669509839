import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AddOn } from '../interfaces/IAddOn';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddOnService {

  api:string = environment.api + "/addOns";
  invMapApi:string = environment.api + "/inventory-mapping/addon";

  constructor(private httpClient: HttpClient) {
  }


  createAddOn(addOn: AddOn): Observable<any> {
    return this.httpClient.post(this.api+"/create", addOn, {withCredentials: true});
  }
  removeAddOn(addOnId: number): Observable<any> {
    return this.httpClient.delete(this.api + "/" + addOnId, {withCredentials: true});
  }
  deleteAddonsByMenu(menuId: number): Observable<any> {
    return this.httpClient.delete(this.api + "/menu/" + menuId, {withCredentials: true});
  }


  getAddOns(menuId: number): Observable<any> {
    return this.httpClient.get( `${this.api}/getAll/${menuId}`);
  }
  updateAddOn(addOn: AddOn): Observable<any> {
    return this.httpClient.put(this.api + "/update", addOn, {withCredentials: true});
  }

  mapWithInventory(addonId: number, inventoryIds:number[]): Observable<any> {
    return this.httpClient.post(`${this.api}/map-with-inventory/${addonId}`, inventoryIds, {withCredentials: true});
  }


  updateMeasurement(mappingId:number, measurement:number, unitId:number){
    return this.httpClient.patch(`${this.invMapApi}/${mappingId}/${measurement}/${unitId}`, {withCredentials: true});
  }

  updateAddOnName(addonId: number, updatedName: string): Observable<any> {
    return this.httpClient.patch(`${this.api}/${addonId}/${updatedName}`, {withCredentials: true});
  }

  updateAddOnPrice(addonId: number, updatedPrice: number): Observable<any> {
    return this.httpClient.patch(`${this.api}/price/${addonId}/${updatedPrice}`, {withCredentials: true});
  }

  toggleAddOn(addOn: AddOn): Observable<any> {
    return this.httpClient.patch(`${this.api}/status/${addOn.id}/${addOn.active}`,null);
  }
  
}
