import { AfterViewInit, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})

export class PreviewComponent implements AfterViewInit {

  text:string='';
  constructor(public dialogRef: MatDialogRef<PreviewComponent>,
              @Inject(MAT_DIALOG_DATA) public data) {
              }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngAfterViewInit(): void {
    this.text = this.data.text.replace('\n', '<br />');
  }

}
