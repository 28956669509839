import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TimeResolver {

  constructor() { }

  getTimeSpanToDays(days: number): Date {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    let calculatePreviousDate = new Date(today);
    calculatePreviousDate.setDate(calculatePreviousDate.getDate() + days);
    return calculatePreviousDate;
  }
  
  getCurrentDate(): Date {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  }

  getTimeSpanFromDays(days: number): Date {
    const today = new Date();
    let calculatePreviousDate = new Date(today);
    calculatePreviousDate.setDate(calculatePreviousDate.getDate() - days);
    return calculatePreviousDate;
  }

  getYesterdayDate(): Date {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    let calculatePreviousDate = new Date(today);
    calculatePreviousDate.setDate(calculatePreviousDate.getDate() - 1);
    return calculatePreviousDate;
  }

  getPreviousSelectedDate(selectedDate): Date {
    const today = new Date(selectedDate);
    today.setHours(0, 0, 0, 0);
    const calculatePreviousDate = new Date(today);
    calculatePreviousDate.setDate(calculatePreviousDate.getDate() - 1);
    return calculatePreviousDate;
  }
    
}