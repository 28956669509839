import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { MenuService } from './menu.service';
import { IMenu } from 'src/app/interfaces/IMenu';
import { BreakpointServices } from 'src/app/appServices/breakpoint.service';
import { ProductService } from 'src/app/appServices/product.service';
import { VariantService } from 'src/app/appServices/variant.service';
import { AddOnService } from 'src/app/appServices/add-on.service';
import { CategoryService } from 'src/app/appServices/category.service';

@Injectable({
  providedIn: 'root'
})
export class MenuResolver {





  menu = new BehaviorSubject<IMenu[]>(null);
  mobileMode: boolean = false;

  constructor(
    private menuService: MenuService,
    private productService: ProductService,
    private toppingService: VariantService,
    private addonService: AddOnService,
    private categoryService: CategoryService,
    private _breakPoint: BreakpointServices
  ) {
    this.initBreakPoints();
  }



  initBreakPoints() {
    this._breakPoint.mobileMode.subscribe(res => {
      this.mobileMode = res;
    });
  }



  /**
   * get Menu from API
   */
  getMenu() {
    this.menuService.getAllMenus().subscribe(res => {
      res && this.setMenu(res);
    });
  }



  /**
    * Initialize Menu
    * @param menu 
  */
  setMenu(menu: IMenu[]) {
    this.menu.next(menu);
  }





  /**
   * Duplicate product
   */
  duplicateProduct(productId: number, menuId: number): Observable<any> {
    return this.menuService.duplicateProduct(menuId, productId);
  }

  /**
   * Create Menu
   */
  createMenu(menu: IMenu): Observable<any> {
    return this.menuService.createMenu(menu);
  }


  /**
   * Get Reports (Counts)
   * @param menuId 
   * @returns 
   */
  getReports(menuId: number, count: string): Observable<any> {
    return this.menuService.getReports(menuId, count);
  }

  
  /**
   * Get Menu by id
   * @param menuId 
   * @returns 
   */
  getMenuById(menuId: number){
    return this.menuService.getMenuById(menuId)
  }


  /**
   * Delete Menu
   * @param menuId 
   * @returns 
   */
  deleteMenu(menuId: number): Observable<any> {
    return this.menuService.deleteMenu(menuId);
  }

  /**
   * delete product by id
   * @param productId 
   */
  deleteProduct(productId: number): Observable<any> {
    return this.productService.deleteProduct(productId);
  }

  /**
   * delete categories
   * @param menuId 
   * @returns 
   */
  deleteCategories(menuId: number): Observable<any> {
    return this.categoryService.deleteCategoiesByMenu(menuId);
  }


  /**
   * delete addons
   * @param menuId 
   * @returns 
   */
  deleteAddons(menuId: number): Observable<any> {
    return this.addonService.deleteAddonsByMenu(menuId);
  }

  /**
   * delete toppings
   * @param menuId 
   * @returns 
   */
  deleteToppings(menuId: number): Observable<any> {
    return this.toppingService.deleteVariantsByMenu(menuId);
  }


  /**
   * get product ids
   * @param menuId 
   */
  getProductIds(menuId: number): Observable<any> {
    return this.productService.getProductIdsByMenu(menuId);
  }


  /**
   * Update Menu
   * @param menuId 
   * @param name 
   * @returns 
   */
  updateName(menuId: number, name: string): Observable<any> {
    return this.menuService.updateMenuName(menuId, name);
  }




  /**
   * Post Update Menu 
   */
  postUpdateMenu(menuId: number, name: string) {
    const tempMenu = [...this.menu.value];
    const index = tempMenu.findIndex(e => e.id === menuId);
    tempMenu[index].name = name;
    this.setMenu(tempMenu);
  }


  /**
    * Clear Menu
  */
  clear() {
    this.setMenu(null);
  }


}
