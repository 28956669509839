import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DesignUtilityServices } from 'src/app/appServices/design-utility.service';
import { AuthService } from 'src/app/appServices/auth/auth.service';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent extends HandleSubscriptionsComponent implements OnInit {

  Form: FormGroup;
  hide = true;
  hide2 = true;
  generateOTPSuccess = false;
  loading: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<UpdatePasswordComponent>,
    private fb: FormBuilder,
    private _authService: AuthService,
    private _du:DesignUtilityServices,
    @Inject(MAT_DIALOG_DATA) public data
  ) { 
    super();
  }

  ngOnInit() {

    this.Form = this.fb.group({
      otp: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(4)]],
    });
    this.generateOTP();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  generateOTP() {
    this.handleSubscription(this._authService.generateOTPByEmail(this.data),
      (res) => {
      }
    );
  }
  updatePassword() {
    const data = {...this.Form.value, emailId: this.data};

    this.handleSubscription(this._authService.updatePassword(this.Form.value),
      (res) => {
        this.onNoClick();
      }
    );
  }

  onChangePassword(){
    this.loading = true;
    const data = {...this.Form.value, emailId: this.data};

    this.handleSubscription(this._authService.changePassword(data),
      (res) => {
        this.loading = false;
        this._du.openCustomSnackBar('Successfully deleted', 'Close', 'success');
        this.dialogRef.close();
      },
      (err) => {
          this.loading = false;
          this._du.openCustomSnackBar('Error: ' + err, 'Close', 'error');
        }
      );
  }

}
