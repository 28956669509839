import { BrowserModule } from '@angular/platform-browser';
import { InjectionToken, NgModule, ErrorHandler } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './appModules/core.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TenentIdentifierInterceptor } from './auth/tenant-identifier/tenent-identifier.interceptor';
import { BasicAuthHtppInterceptorServiceService } from './auth/basic-auth-htpp-interceptor-service.service';
import { BackupApiInterceptor } from './auth/backup-api.interceptor';
import { MatModule } from './appModules/mat-module';
import { ServerErrorInterceptorService } from 'src/app/appServices/server-error-interceptor.service';
import { GlobalErrorHandler } from 'src/app/appServices/global-error-handler';
import { ErrorLoggingService } from 'src/app/appServices/error-logging.service';
import { ErrorMessageService } from 'src/app/appServices/error-message.service';
import * as Rollbar from 'rollbar';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { TastioProgressBarModule } from './includes/tastio-progress-bar/progress-bar.module';
import { NgxStripeModule } from 'ngx-stripe';


/* Roll bar Setup Start */
const rollbarConfig = {
  accessToken: 'aa6e24d4d9eb4cd7b8ea6ea18a214420',
  captureUncaught: true,
  captureUnhandledRejections: true,
};


export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

/* Roll bar Setup End */

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatModule,
    CoreModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    TastioProgressBarModule,
    NgxStripeModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthHtppInterceptorServiceService, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TenentIdentifierInterceptor,
      multi: true
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
    { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptorService, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BackupApiInterceptor,
      multi: true,
    },
    ErrorLoggingService, ErrorMessageService
  ],
  bootstrap: [AppComponent],
  entryComponents: []
})
export class AppModule { }
