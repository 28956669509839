import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TastioProgressBarService } from './progress-bar.service';

@Component({
    selector     : 'tastio-progress-bar',
    templateUrl  : './progress-bar.component.html',
    styleUrls    : ['./progress-bar.component.scss']
})
export class TastioProgressBarComponent implements OnInit, OnDestroy
{
    visible: boolean;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {TastioProgressBarService} _tastioProgressBarService
     */
    constructor(
        private _tastioProgressBarService: TastioProgressBarService
    )
    {
        // Set the defaults

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to the progress bar service properties

        // Visible
        this._tastioProgressBarService.visible
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((visible) => {
                this.visible = visible;
            });

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------




    // setTimeout(function(){

    // 	logoComplete()
    
    // },6000);



    // function logoComplete(){
    // 	// Add class to show the complete state
    // 	$('.loader').addClass('loader--complete');
    
    //   // Remove class to show animation again
    //   setTimeout(function(){
    //     $('.loader').removeClass('loader--complete');
    //   }, 2500);
    
    //   // Call function again
    //   setTimeout(function(){
    //     logoComplete()
    //   },8500);
    // };

    // var path = document.querySelector('.letter');
    // var length = path.getTotalLength();
    // //$('body').append('<p>' + length + '</p>')
}
