import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatModule } from './mat-module';
import { GoNextDirective } from '../appDirectives/go-next.directive';
import { ExpandDirective } from '../appDirectives/expand.directive';
import { FontScaleDirective } from '../appDirectives/font-scale.directive';
import { IntersectionObserverDirective } from '../appDirectives/intersection-observer.directive';
import { SpinDirective } from '../appDirectives/spin.directive';
import { BannerImageDirective } from '../appDirectives/banner-image.directive';
import { LazyImgDirective } from '../appDirectives/lazy-img.directive';
import { StopPropagationDirective } from '../appDirectives/stop-propagation.directive';
import { HttpClientModule } from '@angular/common/http';
import { PromptComponent } from '../includes/popups/prompt/prompt.component';
import { PreviewComponent } from '../includes/popups/preview/preview.component';
import { CustomSnackBarComponent } from '../utilities/custom-snack-bar/custom-snack-bar.component';
import { EditableFieldComponent } from '../utilities/editable-field/editable-field.component';
import { AddInputComponent } from '../utilities/add-input/add-input.component';
import { UploadImageComponent } from '../utilities/upload-image/upload-image.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';
import { RichtextComponent } from '../includes/richtext/richtext.component';
// import { LazyLoadImageDirective } from 'ng-lazyload-image/src/lazyload-image.directive';
import { NumberSuffPipe } from '../appPipes/number-suff.pipe';
import { TrimPipe } from '../appPipes/trim.pipe';
import { SafePipe } from '../appPipes/safe.pipe';
// import { GoogleMapsModule } from '@angular/google-maps';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { QrCodeModule } from 'ng-qrcode';
import { ConfirmDialogComponent } from '../appGuards/unsaved-changes/confirm-dialog/confirm-dialog.component';
import { NoDataModule } from '../includes/no-data/no-data.module';
import { StripeCardPaymentComponent } from '../includes/stripe-card-payment/stripe-card-payment.component';
import { NgxStripeModule } from 'ngx-stripe';

@NgModule({
  declarations: [
    GoNextDirective,
    ExpandDirective,
    FontScaleDirective,
    IntersectionObserverDirective,
    LazyImgDirective,
    StopPropagationDirective,
    // LazyLoadImageDirective,
    PromptComponent,
    PreviewComponent,
    CustomSnackBarComponent,
    EditableFieldComponent,
    AddInputComponent,
    UploadImageComponent,
    RichtextComponent,
    NumberSuffPipe,
    TrimPipe,
    SafePipe,
    SpinDirective,
    BannerImageDirective,
    ConfirmDialogComponent,
    StripeCardPaymentComponent
  ],
  imports: [
    CommonModule,
    MatModule,
    HttpClientModule,
    ImageCropperModule,
    NoDataModule,
    RichTextEditorModule,
    NgxSkeletonLoaderModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyDwAHRBHbdRg54J26x806z9iI3Uj60sVmo",
      libraries: ["places", "geometry", "drawing"]
      /* apiKey is required, unless you are a premium customer, in which case you can use clientId */
    }),
    AgmDirectionModule,
    // GoogleMapsModule,
    QrCodeModule,
    NgxStripeModule
  ],
  exports: [
    MatModule,
    GoNextDirective,
    ExpandDirective,
    FontScaleDirective,
    IntersectionObserverDirective,
    LazyImgDirective,
    StopPropagationDirective,
    // LazyLoadImageDirective,
    HttpClientModule,
    PromptComponent,
    PreviewComponent,
    CustomSnackBarComponent,
    EditableFieldComponent,
    AddInputComponent,
    NgxSkeletonLoaderModule,
    UploadImageComponent,
    ImageCropperModule,
    RichtextComponent,
    NumberSuffPipe,
    TrimPipe,
    SafePipe,
    SpinDirective,
    BannerImageDirective,
    AgmCoreModule,
    AgmDirectionModule,
    // GoogleMapsModule,
    QrCodeModule,
    NoDataModule,
    ConfirmDialogComponent,
    StripeCardPaymentComponent
  ],
  providers: [
  ]
})
export class DesignUtilitiesModule {
}
