import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss']
})
export class UploadImageComponent {

  constructor(
    public dialogRef: MatDialogRef<UploadImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  imageLoaded(): void {
    console.log('Image loaded successfully!');
  }
  
  cropperReady(): void {
    console.log('Cropper is Ready!');
  }

  saveImage(){
    
  }
  
  
  get aspectRatio(){
    if(this.data.aspectRatio){
      return this.data.aspectRatio;
    }
    return 4 / 3
  }
  
  get resizedWidth(){
    if(this.data.resizedWidth){
      return this.data.resizedWidth
    }
    return 0;
  }
  
  /**image cropped related code */

  imageChangedEvent: any = '';
  croppedImage: any = '';

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  } 
  
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.data.croppedImage = this.croppedImage;
  }


  // Drag and Upload (Starts)
  public dragOver = false;
  
  onDropFile(event) {
    this.imageChangedEvent = {target: {files: [event.dataTransfer.files[0]]}} 
    event.stopPropagation();
    event.preventDefault();
    this.dragOver= false;
  }

  onDragOverFile(event) {
    this.dragOver= false;
    event.stopPropagation();
    event.preventDefault();
    this.dragOver= true;
  }
  // Drag and Upload (Ends)
}
