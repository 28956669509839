import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PromptComponent } from '../includes/popups/prompt/prompt.component';
import { CustomSnackBar } from 'src/app/utilities/custom-snack-bar/custom-snack-bar.service';
import { IconRegistryServices } from './icon-registry.service';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class DesignUtilityServices {

  // New Subjects for Updated Header Concepts
  headerColor = new BehaviorSubject('');  // For Header Color
  sideNavClose = new BehaviorSubject(false);
  validator = {
    // mobile: '^((\\+91-?)|0)?[0-9]{11}$', // For prod
    mobile: '^((\\+91-?)|0)?([0-9]{10})$', // For preprod
    // email: '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$',
    email: '^([\\w-]+(?:\\.[\\w-]+)*)@((?:[\\w-]+\\.)*\\w[\\w-]{0,66})\\.([A-Za-z]{2,9}(?:\\.[A-Za-z]{2,9})?)$',
    otp: '^[0-9]*$',
    pin: '^[0-9]*$',
    noWhiteSpace: '\S'
  }
  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private customSnackBar: CustomSnackBar
    ) { 
    }


  //------------------------------------------//
  // Custom Prompt                            //
  //------------------------------------------//
  openPrompt(promptData): Observable<any> {
    const dialogRef = this.dialog.open(PromptComponent, {
      data: promptData,
      maxWidth: '500px',
      width: '90%',
      disableClose: true
    });

    return dialogRef.afterClosed()
  }

  //------------------------------------------//
  // Custom SnakeBar                          //
  //------------------------------------------//
  openSnackBar(message: string, action: string, duration: number, className: string): void {
    this.snackBar.open(message, action, {
      duration: duration * 1000,
      panelClass: [className]
    });
  }

  openCustomSnackBar(message: string, action: string, type: string) {
    if (type === 'success') {
      this.customSnackBar.successMessage(message, action);
    }
    if (type === 'info') {
      this.customSnackBar.infoMessage(message, action);
    }
    if (type === 'error') {
      this.customSnackBar.errorMessage(message, action);
    }
  }

  //------------------------------------------//
  // Hex to RGBA                              //
  //------------------------------------------//
  hexToRgbA(hex, opacity) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')';
    }
    throw new Error('Bad Hex');
  }

  //------------------------------------------------------//
  // Validate for space at start                          //
  //-----------------------------------------------------//
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').match(/^\s/g);
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  //------------------------------------------------------//
  // Validate for coupon code                            //
  //-----------------------------------------------------//
  public couponCodeValidator(control: FormControl) {
    const wordCount = (control.value || '').match(/\S+/g);
    const isValid = wordCount === null || '' || undefined ? true : wordCount.length === 1;
    if (control.value.startsWith(" ") || control.value.endsWith(" ")) {
      control.setValue(control.value.trim());
    }
    return isValid ? null : { 'invalidCouponCode': true };
  }

}
