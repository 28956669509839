import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { INoData } from './INoData';

@Component({
  selector: 'app-no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class NoDataComponent implements OnInit {

  @Input() data:INoData;

  @Output() onModelChanged = new EventEmitter();

  lottieList=[
    'https://assets4.lottiefiles.com/packages/lf20_dzevxknz.json',
    'https://assets9.lottiefiles.com/packages/lf20_xzu7hgpf.json',
    'https://assets7.lottiefiles.com/packages/lf20_udijzyzg.json',
    'https://assets7.lottiefiles.com/packages/lf20_4fewfamh.json',
    'https://assets10.lottiefiles.com/packages/lf20_lc6foY.json',
    'https://assets10.lottiefiles.com/packages/lf20_sYVZ3n.json',
    'https://assets10.lottiefiles.com/packages/lf20_HwRTPu.json',
    'https://assets10.lottiefiles.com/packages/lf20_az2y8tee.json',
    'https://assets7.lottiefiles.com/private_files/lf30_Mm2btt.json',
    'https://assets10.lottiefiles.com/packages/lf20_llpnmgts.json',
    'https://assets3.lottiefiles.com/private_files/lf30_Jogniz.json',
    'https://assets1.lottiefiles.com/packages/lf20_gn57ndas.json',
    'https://assets7.lottiefiles.com/packages/lf20_gbulwrxk.json',

    // 'https://assets3.lottiefiles.com/datafiles/MUp3wlMDGtoK5FK/data.json',
    // 'https://assets9.lottiefiles.com/packages/lf20_scgyykem.json',
    // 'https://assets3.lottiefiles.com/packages/lf20_upw2fyfn.json',
    // 'https://assets1.lottiefiles.com/packages/lf20_l5qvxwtf.json',
    // 'https://assets8.lottiefiles.com/private_files/lf30_ugedlis6.json',
    // 'https://assets7.lottiefiles.com/packages/lf20_s5aOgS.json',
    // 'https://assets8.lottiefiles.com/packages/lf20_lgk0wqey.json'
  ]

  constructor() { }

  ngOnInit() {}

  onEmitAction(){
    this.onModelChanged.emit('');
  }

  get randomLottie(){
    return this.lottieList[Math.floor(Math.random()*this.lottieList.length)];
  }

}
