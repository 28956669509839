// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://preprod-tastio.com',
  adminPanelUrl: 'https://portal.preprod-tastio.com',
  kdsUrl: 'https://kds.preprod-tastio.com',
  tenantMgmtAPI: 'https://preprod-tenantmgmt.com:8443/app/v1',
  firebaseConfig : {
    apiKey: "AIzaSyD5hBJSECW_-0DD2NchivIu_muVyR3wx8w",
    authDomain: "tenant-admin-panel-821f3.firebaseapp.com",
    projectId: "tenant-admin-panel",
    storageBucket: "tenant-admin-panel.appspot.com",
    messagingSenderId: "990601999633",
    appId: "1:990601999633:web:04bb196a5bc026719548b0"
  },
  S3_URL: 'https://tastio-testing.ams3.digitaloceanspaces.com/',
  END_POINTS: 'ams3.digitaloceanspaces.com/',
  SPACE_NAME: 'tastio-testing',
  STRIPE_PUBLIC_KEY: 'pk_test_51IfgJAKBSFkAswCEyeeJrPs3IrVUXwtI5JEIooa46y2egewCc7rZAjaz6ogCZQJPcbujGax3UNmtWWKh3yaU8k6D00rUBLne4r',

  //preprod
  api: 'https://main-api.preprod-tastio.com/app/v1',
  backupBaseUrl: 'https://main-api.preprod-tastio.com/app/v1',
  tenantApi: 'https://tenant-api.preprod-tastio.com/app/v1',
  backupTenantApi: 'https://tenant-api.preprod-tastio.com/app/v1',

  qrAppUrl: 'https://qrapp.preprod-tastio.com',
  registerUrl: 'https://preprod-tastio.web.app/',

  //Development
  // api: 'http://localhost:8080/app/v1',
  // registerUrl: 'https://preprod-tastio.web.app/',
  // qrAppUrl: 'https://qrapp.preprod-tastio.com'



};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
