import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StoresService {


  api:string = environment.api;

  constructor(private httpClient: HttpClient) {
  }


  /**
   * Get Store List
   * @returns 
   */
  getAllStores(): Observable<any> {
    return this.httpClient.get(this.api + "/store");
  }



  /**
   * Create New Store
   * @param storeData 
   * @returns 
   */
  createNewStore(storeData): Observable<any> {
    return this.httpClient.post(this.api + "/store", storeData);
  }



  /**
   * Get Store by Id
   * @param storeId 
   * @returns 
   */
  getStoreById(storeId): Observable<any> {
    return this.httpClient.get(this.api + "/store/" + storeId);
  }





  /**
   * Edit Store
   * @param store 
   * @returns 
   */
  editStore(store): Observable<any> {
    return this.httpClient.put(this.api + "/store", store);
  }




  /**
   * Delete Store by id
   * @param storeId 
   * @returns 
   */
  deleteStore(storeId): Observable<any> {
    return this.httpClient.delete(this.api + "/store/" + storeId);
  }




  /**
   * Toggle Store Active
   * @param storeId 
   * @param active 
   * @returns 
   */
  updateActive(storeId, active): Observable<any> {
    return this.httpClient.patch(this.api + "/store/" + storeId + "/" + active, null);
  }

  /**
   * get store list by stripe account
   * @param stripeAccountId 
   */
  getStoreListByStripeAccount(stripeAccountId: string): Observable<any> {
    return this.httpClient.get(this.api + "/store/stripe/storelist/" + stripeAccountId);
  }


  /**
   * Map Stripe account with Store 
   * @param storeId 
   * @param stripeAccountId 
   * @returns 
   */
   mapAccountWithStore(storeId, stripeAccountId): Observable<any> {
    return this.httpClient.patch(`${this.api}/store/stripe/${storeId}/${stripeAccountId}`,null);
  }


  /**
   * Map Rethink account with Store 
   * @param storeId 
   * @param rethinkAccountId 
   * @returns 
   */
  mapRethinkAccountWithStore(storeId, rethinkAccountId): Observable<any> {
    return this.httpClient.patch(`${this.api}/store/rethink/${storeId}/${rethinkAccountId}`,null);
  }


  /**
   * Get Connected Stores
   * @param stripeAccountId 
   * @returns 
   */
  getConnectedStores(stripeAccountId:string): Observable<any>{
    return this.httpClient.get(`${this.api}/store/stripe/storelist/${stripeAccountId}`);
  }


  /**
   * Get Stripe account by store id
   * @param storeId 
   * @param stripeAccountId 
   * @returns 
   */
  getStripeAcByStoreId(storeId): Observable<any> {
    return this.httpClient.get(`${this.api}/store/stripe/${storeId}`);
  }


  /**
   * Get Rethink account by store id
   * @param storeId 
   * @returns 
   */
  getRethinkAcByStoreId(storeId): Observable<any> {
    return this.httpClient.get(`${this.api}/store/rethink/${storeId}`);
  }

}
