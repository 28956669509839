import { Component, OnInit, HostBinding } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { DesignUtilityServices } from 'src/app/appServices/design-utility.service';
import { ThemeService } from 'src/app/appServices/theme.service';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';

@Component({
  selector: 'app-theme-switch',
  templateUrl: './theme-switch.component.html',
  styleUrls: ['./theme-switch.component.scss']
})
export class ThemeSwitchComponent extends HandleSubscriptionsComponent implements OnInit {
  selectedTheme: any;
  darkThemeEnable: boolean = true;
  theme; // Global Theme
  @HostBinding('class') componentCssClass;
  constructor(
    private _du: DesignUtilityServices,
    public overlayContainer: OverlayContainer,
    private _themeService: ThemeService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getThemeValueFromStorage();
    this.subscribeSelectedTheme();
    this.setTheme();
    this.handleSubscription(this._themeService.theme,
      (res) => {
      this.theme = res;
    });
  }

  /**
   * get theme value from storage
   */
  getThemeValueFromStorage(): void {
    this.darkThemeEnable = localStorage.getItem('darkThemeEnable') ?
      JSON.parse(localStorage.getItem('darkThemeEnable')) : this.darkThemeEnable;
    // this.selectedTheme = localStorage.getItem('themeName');
    if(localStorage.getItem('themeName')){
      this._themeService.selectedTheme.next(JSON.parse(localStorage.getItem('themeName')))
    }
  }
  /**
   * Set Theme
   */
  setTheme() {
    if (this.darkThemeEnable) {
      this.removeThemeClass();
      this.overlayContainer.getContainerElement().classList.add(this.selectedTheme.value + '-dark-theme');
      this.componentCssClass = this.selectedTheme.value + '-dark-theme';
      this._themeService.theme.next(this.selectedTheme.value + '-dark-theme');
    } else {
      this.removeThemeClass();
      this.overlayContainer.getContainerElement().classList.add(this.selectedTheme.value + '-light-theme');
      this.componentCssClass = this.selectedTheme.value + '-light-theme';
      this._themeService.theme.next(this.selectedTheme.value + '-light-theme');
    }
    this.setThemeInLocalStorage();
  }

  /**
   * Remove Theme classes
   */
  removeThemeClass() {
    const classList = this.overlayContainer.getContainerElement().classList;
    for (let index = 0; index < classList.length; index++) {
      const className = classList[index];
      if (className.indexOf('theme') > -1) {
        this.overlayContainer.getContainerElement().classList.remove(className);
      }
    }
  }

  /**
   * set theme in local storage 
   * @param darkTheme 
   */
  setThemeInLocalStorage() {
    localStorage.setItem('darkThemeEnable', JSON.stringify(this.darkThemeEnable));
    localStorage.setItem('themeName', JSON.stringify(this.selectedTheme))
  }

  // subscribe
  subscribeSelectedTheme() {
    this.handleSubscription(this._themeService.selectedTheme,
      (res) => {
      this.selectedTheme = res;
      this.setTheme();
    });
  }
  
}
