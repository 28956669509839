import { Directive, Input, HostListener, HostBinding } from '@angular/core';

@Directive({
  selector: '[spin]'
})
export class SpinDirective {
  @HostBinding('class.spin') animate = false
  @HostListener('click') myClick(){
    this.animate = true;
    setTimeout(() => {
      this.animate = false;
    }, 400);
  }
}
