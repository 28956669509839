<mat-nav-list>
    <div mat-subheader>Notifications</div>
    <mat-divider></mat-divider>
    <mat-list-item *ngFor="let notification of notificationList">
        <div class="text" [ngClass]="{ 'opacity' : notification.read}">
            <ng-container *ngIf="!notification.notificationModel.notificationObject.type">
                <ng-container *ngIf="notification.notificationModel.notificationObject.customerName">
                    New Order &bull; {{notification.notificationModel.notificationObject.customerName}}
                </ng-container>
                <ng-container *ngIf="!notification.notificationModel.notificationObject.customerName">
                    {{notification.notificationModel.notificationObject}}
                </ng-container>
            </ng-container>
            <ng-container
                *ngIf="notification.notificationModel.notificationObject.type?.toUpperCase() === 'notify-restro'.toUpperCase()">
                <div>ORD{{notification.notificationModel.notificationObject.orderId}} &bull;
                    {{notification.notificationModel.notificationObject.name}}
                </div>
                <div class="message">
                    {{notification.notificationModel.notificationObject.message}}
                </div>

            </ng-container>
            <small>
                {{notification.notificationDate | dateAgo}}
            </small>
        </div>
        <mat-divider></mat-divider>
    </mat-list-item>
</mat-nav-list>