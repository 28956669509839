import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TimeResolver } from '../appResolver/time.resolver';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  api:string = environment.api + "/notification";

  constructor(
    private httpClient: HttpClient,
    private _timeResolver: TimeResolver
    ) {
  }

  getNotificationsAfterId(id: number): Observable<any> {
    const from = this._timeResolver.getYesterdayDate().getTime();
    const to = this._timeResolver.getTimeSpanToDays(1).getTime();
    return this.httpClient.get(this.api + "/" + id + "/" + from + "/" + to);
  }

  /**
  * mark all read notifications
  * @param ids 
  */
  markAllRead(ids: number[]): Observable<any> {
    return this.httpClient.get(this.api + "/read?ids=" + ids);
  }

  /**
* clear all notifications
* @param ids 
*/
  clearAll(ids: number[]): Observable<any> {
    return this.httpClient.get(this.api + "/clear?ids=" + ids);
  }

  


}
