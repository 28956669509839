import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IMenu } from 'src/app/interfaces/IMenu';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MenuService {


  api:string = environment.api + "/menu";

  constructor(private httpClient: HttpClient) {
  }


  /**
   * Duplicate product
   * @param newMenuId
   * @param  productId
   * @returns 
   */
  duplicateProduct(menuId: number, productId: number): Observable<any> {
    return this.httpClient.get(`${this.api}/duplicate/product/${menuId}/${productId}`);
  }


  /**
   * create menu
   * @param menu 
   * @returns 
   */
  createMenu(menu: IMenu): Observable<any> {
    return this.httpClient.post(this.api, menu);
  }



  /**
   * Get Reports (Counts)
   * @param menuId 
   * @returns 
   */
  getReports(menuId: number, count: string): Observable<any>{
    if(count===null){
      return this.httpClient.get(`${this.api}/report/${menuId}`);
    }
    return this.httpClient.get(`${this.api}/report/${menuId}/${count}`);
  }



  /**
   * get all menu
   * @returns 
   */
  getAllMenus(): Observable<any> {
    return this.httpClient.get(this.api);
  }


  /**
   * get menu by id
   * @returns 
   */
  getMenuById(menuId:number): Observable<any> {
    return this.httpClient.get(`${this.api}/${menuId}`);
  }

  /**
   * delete menu
   * @param menuId 
   * @returns 
   */
  deleteMenu(menuId: number): Observable<any> {
    return this.httpClient.delete(`${this.api}/${menuId}`);
  }


  /**
   * update menu
   * @param menuId 
   * @param name 
   * @returns 
   */
  updateMenuName(menuId: number, name: string): Observable<any> {
    return this.httpClient.patch(`${this.api}/${menuId}/${name}`, null);
  }
  
  /**
   * 
   * @param storeId 
   * @param menuId 
   */
  mapMenuWithStore(storeId: number, menuId: number) {
    return this.httpClient.patch(`${this.api}/mapStore/${storeId}/${menuId}`, null);
  }

}
