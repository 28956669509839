import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { IStore } from 'src/app/interfaces/IStore';
import { DesignUtilityServices } from '../design-utility.service';
import { StoresService } from './stores.service';

@Injectable({
  providedIn: 'root'
})
export class StoreResolver {
  storeList = new BehaviorSubject<IStore[]>(null);
  selectedStore = new BehaviorSubject<IStore>(null);
  loading = new BehaviorSubject<boolean>(false);

  constructor(
    private _storeService: StoresService,
    private _du: DesignUtilityServices,
    private router: Router
  ) {
  }


  /**
   * Get Store by Id
   * @param id 
   * @returns 
   */
  getStoreById(id: number) {
    if (this.storeList.value && this.storeList.value.length > 0) {
      const storeListCopy = [...this.storeList.value];
      const temp = storeListCopy.findIndex(e => e.id === id);
      return of(storeListCopy[temp]);
    } else {
      return this._storeService.getStoreById(id);
    }
  }




  /**
   * subscribe store list
   */
  subscribeStoreList(relode: boolean = false) {
    if (!relode && this.storeList.value) {
      return
    }
    this._storeService.getAllStores().subscribe((res: IStore[]) => {
      this.setStoreList(res);
      this.setSelectedStore(this.storeList.value[0] || null);
    });
  }


  /**
   * Set Store list
   * @param storeList 
   */
  setStoreList(storeList: IStore[]) {
    this.storeList.next(storeList);
  }



  /**
   * set selected store
   * @param store 
   */
  public setSelectedStore(store: IStore) {
    this.selectedStore.next(store);
  }





  /**
   * Create New Store
   * @param storeData 
   * @returns 
   */
  createNewStore(storeData: any) {
    this._storeService.createNewStore(storeData).subscribe(res => {
      this.subscribeStoreList(true);
      this._du.openCustomSnackBar('Successfully created', '', 'success');
      this.router.navigate(['/stores/stores-list']);
      this.loading.next(false);
    }, err => {
      this._du.openCustomSnackBar('Error: ' + err.error.message, '', 'error');
      this.loading.next(false);
    });
  }






  /**
   * Toggle Store Active
   * @param store 
   */
  activateStore(store: IStore) {
    this._storeService.updateActive(store.id, store.active).subscribe(res => {
      this._du.openCustomSnackBar('Successfully updated', 'Close', 'success');
      this.postUpdate(store);
    }, err => {
      this._du.openCustomSnackBar('Unable to update, Please contact us.', 'Close', 'error');
      store.active = !store.active;
      this.postUpdate(store);
    });
  }






  /**
   * Update Store
   * @param store 
   */
  updateStore(store: IStore) {
    this._storeService.editStore(store).subscribe(res => {
      this._du.openCustomSnackBar('Successfully updated', 'Close', 'success');
      this.router.navigate(['/stores/stores-list']);
      this.postUpdate(store);
    }, err => {
      this._du.openCustomSnackBar('Unable to update, Please contact us.', 'Close', 'error');
    });
  }



  /**
   * Post Update
   * @param store 
   */
  postUpdate(store: IStore) {
    const storeListCopy = [...this.storeList.value];
    const temp = storeListCopy.findIndex(e => e.id === store.id);
    storeListCopy[temp] = store;
    this.setStoreList(storeListCopy);
  }




  deleteStore(id: number) {
    let data = {
      title: "Are you sure?",
      text: 'You old orders reference will be deleted.'
    }

    this._du.openPrompt(data).subscribe(confirm => {
      if (confirm) {
        this._storeService.deleteStore(id).subscribe(res => {
          const storeListCopy = [...this.storeList.value];
          const index = storeListCopy.findIndex(e => e.id === id);
          storeListCopy.splice(index, 1);
          this.setStoreList(storeListCopy);
          this._du.openCustomSnackBar('Successfully deleted', 'Close', 'success');
        }, err => {
          this._du.openCustomSnackBar('Error: ' + err, 'Close', 'error');
        });
      }
    })
  }

  getStoreListByStripeAccount(stripeAccountId: string): Observable<any> {
    return this._storeService.getStoreListByStripeAccount(stripeAccountId);
  }



  /**
   * Map Stripe account with Store 
   * @param storeId 
   * @param stripeAccountId 
   * @returns 
   */
  mapAccountWithStore(storeId, stripeAccountId): Observable<any> {
    return this._storeService.mapAccountWithStore(storeId, stripeAccountId);
  }
  
  
  /**
   * Map Rethink account with Store 
   * @param storeId 
   * @param rethinkAccountId 
   * @returns 
   */
  mapRethinkAccountWithStore(storeId, rethinkAccountId): Observable<any> {
    return this._storeService.mapRethinkAccountWithStore(storeId, rethinkAccountId);
  }

  
  /**
   * Get Connected Stores
   * @param stripeAccountId 
   * @returns 
   */
  getConnectedStores(stripeAccountId:string): Observable<any>{
    return this._storeService.getConnectedStores(stripeAccountId);
  }


  /**
   * Get Stripe account by store id
   * @param storeId 
   * @param stripeAccountId 
   * @returns 
   */
   getStripeAcByStoreId(storeId): Observable<any> {
    return this._storeService.getStripeAcByStoreId(storeId);
  }


  /**
   * Get Rethink account by store id
   * @param storeId 
   * @returns 
   */
  getRethinkAcByStoreId(storeId): Observable<any> {
    return this._storeService.getRethinkAcByStoreId(storeId);
  }


  /*------------------------------------------------------------------
    Cleanup Methods
  -------------------------------------------------------------------*/

  /**
   * clear Selected store
   */
  public clearSelectedStore(): void {
    this.selectedStore.next(null);
  }


  /**
   * Clear storage
   */
  clear() {
    this.storeList.next(null);
    this.selectedStore.next(null);
  }

}