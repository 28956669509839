import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountSettingsService {

  api:string = environment.api + "/account-settings";

  constructor(private httpClient: HttpClient) {
  }

  getAccountInfo(): Observable<any> {
    return this.httpClient.get(this.api);
  }

  updateAccountInfo(accountInfo: any): Observable<any> {
    return this.httpClient.put(this.api, accountInfo, { withCredentials: true });
  }

  
  updateLogo(url){
    return this.httpClient.patch(`${this.api}/logo/${url}`, url, {withCredentials: true});
  }
  
  updateDarkLogo(darklogourl){
    return this.httpClient.patch(`${this.api}/dark-logo/${darklogourl}`, darklogourl, {withCredentials: true});
  }

  updateEmail(email){
    return this.httpClient.patch(`${this.api}/email/${email}`, email, {withCredentials: true});
  }

  updateMobile(mobile){
    return this.httpClient.patch(`${this.api}/mobile-number/${mobile}`, mobile, {withCredentials: true});
  }

  updateRestaurantName(restaurant_name){
    return this.httpClient.patch(`${this.api}/name/${restaurant_name}`, restaurant_name, {withCredentials: true});
  }

  updateActiveStatus(active){
    return this.httpClient.patch(`${this.api}/active/${active}`, active, {withCredentials: true});
  }

  /**
   * Update Business Type
   * @param businessType 
   * @returns 
   */
  updateBusinessType(businessType: string){
    return this.httpClient.patch(`${this.api}/business-type/${businessType}`, {withCredentials: true});
  }

}
